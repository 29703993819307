(function (app, $, window, document, undefined) {
  'use strict';

  // ---------------------------------------------------------------------------
  // Forms
  // ---------------------------------------------------------------------------

  // Phonenumber
  $('.field-group--phone .small').keyup(function () {
    if (this.value.length == this.maxLength) {
      $(this).next('input').focus();
    }
  });

  // ---------------------------------------------------------------------------
  // Carousels
  // ---------------------------------------------------------------------------

  var $carouselMain = $('.carousel-main'),
    $carouselCollegeMain = $('.carousel-college-main');

  $carouselMain.flickity({
    contain: true,
    pageDots: true,
    prevNextButtons: false,
    autoPlay: 9999,
    // bgLazyLoad: true,
    watchCSS: true,
  });

  var $carouselWinners = $('.home-winners__slider-body');
  $carouselWinners.flickity({
    contain: true,
    draggable: false,
    autoPlay: true,
    prevNextButtons: false,
    pageDots: false,
  });

  // $carouselCollegeMain.flickity({
  // 	contain: true,
  // 	pageDots: false,
  // 	prevNextButtons: false,
  // 	watchCSS: true
  // });

  // ---------------------------------------------------------------------------
  // Team Toyota Social
  // ---------------------------------------------------------------------------

  // Team Toyota
  var floater = false;
  var top = $(window).scrollTop();
  var left = $(window).scrollLeft();

  var memberButtonClick = function (member) {
    console.log('memberButtonClick: ' + member);

    // On click find if this has data-member.
    // Match it wtih if-elses
    // On match, fire up the flickity slider
    $('body').css('overflow', 'hidden');
    $(window).scroll(function () {
      $(this).scrollTop(top).scrollLeft(left);
    });
    $('.team-toyota-lightbox').lightbox_me({
      centered: true,
      modalCSS: {
        top: '0px',
      },
      closeSelector: '.toyota-lightbox-close',
      showOverlay: false,
      onLoad: function () {
        $TeamToyota.flickity('resize');
        $TeamToyota.flickity('reloadCells');
      },
      onClose: function () {
        floater = false;
        $('body').css('overflow', 'auto');
        $(window).unbind('scroll');
        $('.carousel-team-toyota-nav div').removeClass('is-selected');
        $('.carousel-team-toyota .carousel-cell').removeClass('is-selected');
        $TeamToyota.flickity('destroy');
      },
      // appearEffect: "fadeIn",
    });
    $('#slider')
      .css({
        top: top,
        left: left,
      })
      .animate(
        {
          'margin-left': floater ? -400 : 0,
        },
        500
      );
    floater = !floater;

    var $TeamToyota = $('.carousel-team-toyota');
    var $TeamToyotaWrap = $('.carousel-team-toyota-nav-wrap');
    var $TeamToyotaNav = $('.carousel-team-toyota-nav');

    $TeamToyota.flickity({
      contain: true,
      pageDots: false,
      bgLazyLoad: true,
      imagesLoaded: true,
      prevNextButtons: false,
      draggable: false,
      adaptiveHeight: true,
    });

    if (member === 'vandam') {
      $TeamToyota.flickity('select', 0, false, true);
      $('.carousel-team-toyota-nav div:nth-child(1)').addClass('is-selected');
    }

    if (member === 'scroggins') {
      $TeamToyota.flickity('select', 1, false, true);
      $('.carousel-team-toyota-nav div:nth-child(2)').addClass('is-selected');
    }

    if (member === 'swindle') {
      $TeamToyota.flickity('select', 2, false, true);
      $('.carousel-team-toyota-nav div:nth-child(3)').addClass('is-selected');
    }

    if (member === 'iaconelli') {
      $TeamToyota.flickity('select', 3, false, true);
      $('.carousel-team-toyota-nav div:nth-child(4)').addClass('is-selected');
    }
    $TeamToyotaNav.off('click');
    $TeamToyotaNav.on('click', '.carousel-cell', function () {
      var index = $(this).index();
      $('.carousel-team-toyota-nav .carousel-cell').removeClass('is-selected');
      $(this).toggleClass('is-selected');
      $TeamToyota.flickity('select', index);
    });

    // On left arrow go to previous slide.
    $('.carousel-team-toyota-nav-wrap .previous').off('click');
    $('.carousel-team-toyota-nav-wrap .previous').on('click', function () {
      $TeamToyota.flickity('previous');

      $(this).data('prevclicked', true);
    });
    // On right arrow go to next slide.
    $('.carousel-team-toyota-nav-wrap .next').off('click');
    $('.carousel-team-toyota-nav-wrap .next').on('click', function () {
      $TeamToyota.flickity('next');

      $(this).data('nextclicked', true);
    });

    // On left arrow with class .prevous, remove class on current selected cell, go to previous one and add is selected
    $TeamToyotaWrap.off('click', '.flickity-prev-next-button.previous');
    $TeamToyotaWrap.on(
      'click',
      '.flickity-prev-next-button.previous',
      function () {
        if (
          $('.carousel-team-toyota-nav-wrap .is-selected').is(':first-child')
        ) {
          // Don't do anyting if this is true
        } else {
          $('.carousel-team-toyota-nav-wrap .is-selected')
            .removeClass('is-selected')
            .prev()
            .addClass('is-selected');
        }
      }
    );

    // On right arrow with class .next, remove class on current selected cell, go to next one and add is selected
    $TeamToyotaWrap.off('click', '.flickity-prev-next-button.next');
    $TeamToyotaWrap.on('click', '.flickity-prev-next-button.next', function () {
      if ($('.carousel-team-toyota-nav-wrap .is-selected').is(':last-child')) {
        // Don't do anyting if this is true
      } else {
        $('.carousel-team-toyota-nav-wrap .is-selected')
          .removeClass('is-selected')
          .next()
          .addClass('is-selected');
      }
    });
  };

  $('.member-button .btn').on('click', function (e) {
    var $memberData = $(this).data('member');
    memberButtonClick($memberData);
    e.preventDefault();
  });
  // Team Toyota

  // ---------------------------------------------------------------------------
  // Gallery / Lightbox
  // ---------------------------------------------------------------------------

  // Gallery Lightbox
  $('.album-item').on('click', function (e) {
    var currentAlbum = $(this);
    var currentAblumTitle = $(this).attr('data-album');
    var currentLightbox = $(
      ".gallery-lightbox[data-album='" + currentAblumTitle + "']"
    );
    var $galleryPath = $(
      ".gallery-lightbox[data-album='" +
        currentAblumTitle +
        "'] .carousel-gallery .carousel-cell"
    );
    var galleryCounteList = $galleryPath.length;
    var albumCounterTotal = $('#CounterSlides').html(galleryCounteList);

    if (galleryCounteList) {
      currentLightbox.lightbox_me({
        centered: true,
        closeSelector: '.js-gallery-close',
        overlayCSS: {
          background: 'black',
          opacity: 0.7,
        },
        // zIndex: 5,
        modalCSS: {
          '-webkit-transform': 'translateZ(0)',
        },
        onLoad: function () {
          $currentGallery.flickity('resize');
          $currentGalleryNav.flickity('resize');
        },
      });
    }

    var $currentGallery = $(
      ".carousel-gallery[data-album='" + currentAblumTitle + "']"
    );
    var $currentGalleryNav = $(
      ".carousel-gallery-nav[data-album='" + currentAblumTitle + "']"
    );

    // 1st carousel, main
    $currentGallery.flickity({
      pageDots: false,
      lazyLoad: true,
      arrowShape: {
        x0: 35,
        x1: 55,
        y1: 50,
        x2: 70,
        y2: 50,
        x3: 50,
      },
      contain: true,
      // ,initialIndex: 2
    });

    // 2nd carousel, navigation
    $currentGalleryNav.flickity({
      asNavFor: $currentGallery[0],
      contain: true,
      pageDots: false,
      prevNextButtons: false,
    });

    setTimeout(function () {
      function galleryCounter() {
        var galleryCounterNumber = $galleryPath.index(
          $(
            ".carousel-gallery[data-album='" +
              currentAblumTitle +
              "'] .carousel-cell.is-selected"
          )
        );
        var albumCounterActive = $(
          ".gallery-lightbox[data-album='" +
            currentAblumTitle +
            "'] #CounterActiveSlide"
        ).html(galleryCounterNumber + 1);
      }

      galleryCounter();
      $currentGallery.on('select.flickity', function () {
        galleryCounter();
      });
    }, 350);
    e.preventDefault();
  });

  // Gallery Year Lightbox

  var galleryYearInit = function () {
    $('.year-sorting').hide();

    var currentYearAlbum = $(this);
    var currentYear = currentYearAlbum.attr('data-year');
    // console.log(currentYear);
    var $yearGalleryPath = $(
      ".gallery-second[data-year='" + currentYear + "']"
    );
    // console.log($yearGalleryPath);
    var $yearGalleryPathMain = $(
      ".gallery-second[data-year='" + currentYear + "'] .carousel-gallery"
    );
    var $yearGalleryPathNav = $(
      ".gallery-second[data-year='" + currentYear + "'] .carousel-gallery-nav"
    );
    // console.log(currentYearAlbum);

    $yearGalleryPath.show('slow', function () {
      $yearGalleryPath.show();
      // 1st carousel, main
      $yearGalleryPathMain.flickity({
        pageDots: false,
        arrowShape: {
          x0: 35,
          x1: 55,
          y1: 50,
          x2: 70,
          y2: 50,
          x3: 50,
        },
        contain: true,
        lazyLoad: true,
        // ,initialIndex: 2
      });

      // 2nd carousel, navigation
      $yearGalleryPathNav.flickity({
        asNavFor: $yearGalleryPathMain[0],
        contain: true,
        pageDots: false,
        prevNextButtons: false,
      });

      publish('lightboxYearGalleryRefresh');
    });

    $('.gallery-previous').on('click', function (e) {
      e.preventDefault();
      $yearGalleryPath.hide();
      $yearGalleryPath.hide();
      $('.year-sorting').show();
    });
    return false;
  };

  var lightboxYearGalleryReloadFlickity = function () {
    var $galleries = $(
      '.gallery-lightbox-year .gallery-second .carousel.flickity-enabled'
    );
    if ($galleries.length) {
      setTimeout(function () {
        $galleries.flickity('resize');
        $galleries.flickity('reloadCells');
      }, 500);
    }
  };

  subscribe('lightboxYearGalleryRefresh', lightboxYearGalleryReloadFlickity);

  var galleryLightboxYear = function () {
    $('.gallery-second').hide();
    $('.year-sorting').show();
    $('.gallery-lightbox-year').lightbox_me({
      centered: true,
      closeSelector: '.js-gallery-close',
      overlayCSS: {
        background: 'black',
        opacity: 0.7,
      },
      // zIndex: 5,
      modalCSS: {
        '-webkit-transform': 'translateZ(0)',
      },
      onLoad: function () {
        publish('lightboxYearGalleryRefresh');
      },
      onClose: function () {
        $('.gallery-second').hide();
        $('.gallery-second').hide();
        $('.year-sorting').show();
      },
    });

    // $('.year-grid-item').on('click', function(e) {
    //
    //
    // });

    return false;
  };

  $(document).on('click', '.year-grid-item', galleryYearInit);

  $(document).on('click', '.album-year', galleryLightboxYear);

  // $('.album-year').click(function(e) {
  //
  //
  //
  // 	e.preventDefault();
  // });

  // ---------------------------------------------------------------------------
  // File Uploading
  // ---------------------------------------------------------------------------

  // File upload
  $('.photo-upload input').each(function () {
    var $input = $(this),
      $label = $input.next('label'),
      labelVal = $label.html();

    $input.on('change', function (e) {
      var fileName = '';

      if (this.files && this.files.length > 1)
        fileName = (this.getAttribute('data-multiple-caption') || '').replace(
          '{count}',
          this.files.length
        );
      else if (e.target.value) fileName = e.target.value.split('\\').pop();

      if (fileName) $label.find('span').html(fileName);
      else $label.html(labelVal);
    });

    // Firefox bug fix
    $input
      .on('focus', function () {
        $input.addClass('has-focus');
      })
      .on('blur', function () {
        $input.removeClass('has-focus');
      });
  });

  $('body').on('focus', 'input[name="year"]', function (event) {
    $('input[name="_year"]').prop('checked', true);
  });

  function ethicsCheckboxes() {
    var all_checked = true;

    $('.ethics .li-checkbox input[type=checkbox]').each(function (
      index,
      element
    ) {
      if (!$(element).prop('checked')) {
        all_checked = false;
      }
    });

    $('.ethics-gallery-button').toggleClass('js-disabled', !all_checked);
    $('.ethics-gallery-button input').prop('disabled', !all_checked);
  }

  $('.ethics .li-checkbox input[type=checkbox]').on('change', function (event) {
    ethicsCheckboxes();
  });

  ethicsCheckboxes();
})((window.app = window.app || {}), jQuery, window, document);
